<template>

<div class="login">

	<div class="login-code" :class="{'is-checking': is.checking, 'is-failed': is.failed}">

		<app-icon icon="assistant.logo" class="login-icon" />

		<app-logo class="login-logo" term="assistant" />
		
		<div class="login-code-wrapper">

			<input class="login-code-input" ref="code" type="text" maxlength="1" v-for="(value, index) in code" :key="index" :placeholder="0" v-on:keydown="onKeyDown(parseInt(index), $event)" />

		</div>

		<div class="login-code-alternative" v-on:click="onAccountClick">
			<span>Or login with account</span>
			<i class="fa-solid fa-circle-chevron-right"></i>
		</div>

		<app-icon icon="loading" v-if="is.checking" class="login-loading" />

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			code: {
				1: null,
				2: null,
				3: null,
				4: null,
				5: null,
				6: null
			},
			is: {
				checking: false,
				failed: false
			}
		}

	},

	methods: {

		onAccountClick: function() {

			// 

		},

		onKeyDown: function(index, e) {

			if (this.$_.contains(['0','1','2','3','4','5','6','7','8','9','Backspace','Delete'], e.key.toString())) {

				if (e.key !== 'Backspace' && e.key !== 'Delete') {

					if (e.target.value.length === 1) e.target.value = ''

					e.target.value = e.key

					this.is.failed = false

					if (index < 6) {
						
						this.$refs.code[index].focus()

					} else {

						this.$refs.code[index - 1].blur()

						this.$nextTick(function() {

							this.submit()

						}.bind(this))

					}

				} else {

					if (e.target.value.length === 1) {
						
						e.target.value = ''

					} else {

						if (index > 1) this.$refs.code[index - 2].focus()

					}

				}

				this.code[index] = e.target.value

			} 
			
			e.preventDefault()

		},

		submit: function() {

			this.is.checking = true

			this.$api.post('login', {
				code: this.$_.values(this.code).join('')
			}).then(async function(json) {

				this.$store.commit('key', json.key)

				await this.$api.get('session')

				this.$router.push({
					name: 'Dashboard'
				})

			}.bind(this), function() {
				
				this.is.checking = false
				this.is.failed = true

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.login {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #1e3054;
}

.login-icon {
	font-size: 96px;
	color: #fff;
}

.login-icon >>> i {
	--fa-primary-color: #ffffff;
	--fa-secondary-color: #ffbe7a;
	--fa-secondary-opacity: 1;
} 

.login-logo {
	margin: 4px 0px;
}

.login-code {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	animation-duration: 1s;
    animation-fill-mode: both;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.login-code-wrapper {
	display: flex;
}

.login-code.is-checking {
	pointer-events: none;
}

.login-code.is-failed {
	animation-name: shake;
}

.login-code-input {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	font-size: 24px;
	font-weight: bold;
	background-color: #fff;
	text-align: center;
	line-height: 40px;
	margin: 5px;
}

.login-code-input::placeholder {
	color: #eee;
}

.login-code.is-failed .login-code-input {
	background-color: #c13c3c;
	color: #fff;
}

.login-code.is-failed .login-code-input::placeholder {
	color: #e37474;
}

.login-loading {
	position: absolute;
	left: 50%;
	bottom: -56px;
	font-size: 32px;
	color: #fff;
	transform: translateX(-50%);
}

@keyframes shake {
0%, 100% {transform: translate(-50%, -50%);}
    10%, 30%, 50%, 70%, 90% {transform: translate(calc(-50% - 10px), -50%);}
    20%, 40%, 60%, 80% {transform: translate(calc(-50% + 10px), -50%);}
}

.login-code-alternative {
	color: #fff;
	font-weight: 500;
	margin-top: 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.login-code-alternative i {
	color: #ffbe7a;
	font-size: 16px;
	margin-left: 5px;
}

</style>
